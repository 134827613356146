import { HttpClient, HttpResponse } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import {
    DocumentTemplate,
    DocumentTemplateQueryResult,
    StatusType,
    SystemEntity,
} from '@wdx/clmi/api-models';
import { saveAs } from 'file-saver';
import * as luxon from 'luxon';
import { DateTime } from 'luxon';
import { Observable, of } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { ConfigService } from '../../services/config.service';
import { ApiPaginationService } from '@wdx/clmi/api-services/services';
import { MAX_PAGE_SIZE } from '@wdx/shared/utils';

interface DocumentTemplateParams {
    entityType?: SystemEntity;
    documentTypeCode?: string;
}

@Injectable()
export class DocumentTemplatesService {
    private apiPaginationService = inject(ApiPaginationService);
    private http = inject(HttpClient);
    private config = inject(ConfigService);

    getAllDocumentTemplates(
        params: DocumentTemplateParams
    ): Observable<DocumentTemplate[]> {
        return this.getDocumentTemplates(params).pipe(
            switchMap((result) =>
                this.getDocumentTemplatesPages(result, params, result.results)
            )
        );
    }

    private getDocumentTemplatesPages(
        result: DocumentTemplateQueryResult,
        params: DocumentTemplateParams,
        accumulatedResults: DocumentTemplate[] = []
    ): Observable<DocumentTemplate[]> {
        const { page, totalPages } = result.paging;
        if (totalPages === page) {
            return of(accumulatedResults);
        }

        return this.getDocumentTemplates(params, page + 1).pipe(
            switchMap((nextResult) =>
                this.getDocumentTemplatesPages(nextResult, params, [
                    ...accumulatedResults,
                    ...nextResult.results,
                ])
            )
        );
    }

    private getDocumentTemplates(
        params: DocumentTemplateParams,
        pageNumber = 1
    ): Observable<DocumentTemplateQueryResult> {
        return this.http.get<DocumentTemplateQueryResult>(
            `${this.config.getConfiguration().API_BASE}/documenttemplate`,
            {
                params: {
                    ...(params.entityType
                        ? { entityType: params.entityType }
                        : {}),
                    ...(params.documentTypeCode
                        ? { documentTypeCode: params.documentTypeCode }
                        : {}),
                },
                headers: this.apiPaginationService.mapPaginationOptions({
                    pageNumber,
                    pageSize: MAX_PAGE_SIZE,
                }),
            }
        );
    }

    generateDocument(id: string, entityId: string) {
        return this.http
            .get<Blob>(
                `${
                    this.config.getConfiguration().API_BASE
                }/DocumentTemplate/${id}/generate/${entityId}`,
                {
                    observe: 'response',
                    responseType: 'blob' as 'json',
                }
            )
            .pipe(tap((response) => this.download(response)));
    }

    download(response: HttpResponse<Blob>) {
        const contentDisposition: string = response.headers.get(
            'content-disposition'
        );
        const filename: string[] = contentDisposition?.match(
            // eslint-disable-next-line no-useless-backreference
            /filename[^;=\n]*=['"](.*?\2|[^;\n]*)([.]\w*)/
        );
        const dateNow: string =
            '_' +
            luxon.DateTime.fromJSDate(new Date()).toLocaleString(
                DateTime.DATE_SHORT
            );
        const contentType: string = response.headers.get('content-type');
        const blob: Blob = new Blob([response.body], { type: contentType });

        saveAs(blob, filename[1] + dateNow + filename[2]);
    }

    setDocumentStatus(
        id: string,
        status: StatusType
    ): Observable<DocumentTemplate> {
        return this.http.patch<DocumentTemplate>(
            `${
                this.config.getConfiguration().API_BASE
            }/DocumentTemplate/${id}/status/${status}`,
            {}
        );
    }

    deleteDocumentTemplate(id: string): Observable<DocumentTemplate> {
        return this.http.delete(
            `${this.config.getConfiguration().API_BASE}/DocumentTemplate/${id}`
        );
    }

    generateDocumentTemplate(
        entityId: string,
        entityType: SystemEntity,
        templateId: string
    ): Observable<Document> {
        return this.http.patch<Document>(
            `${
                this.config.getConfiguration().API_BASE
            }/DocumentTemplate/${entityId}/${entityType}/generate/${templateId}`,
            {}
        );
    }
}
